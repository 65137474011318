import { useState, useEffect } from "react";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import { AiOutlineImport } from "react-icons/ai";

const HeaderRow = ({ setPopOpen, headerKey, ind, headers, setHeaders, val }) => {
  const [newKey, setNewKey] = useState(headerKey)
  const [keyDisabled, setKeyDisabled] = useState(true);
  const [value, setValue] = useState(val)
  const [valueDisabled, setValueDisabled] = useState(true)

  useEffect(() => {
    setNewKey(headerKey)
    setValue(val)
  }, [val])

  const handleKeySave = () => {
    setKeyDisabled(!keyDisabled)
    let temp = [...headers]
    temp[ind][0] = newKey
    let formattedHeaders = {}
    temp.forEach(header => formattedHeaders[header[0]] = header[1] )
    setHeaders(formattedHeaders)
  }

  const handleValueSave = () => {
    setValueDisabled(!valueDisabled)
    let temp = [...headers]
    temp[ind][1] = value
    let formattedHeaders = {}
    temp.forEach(header => formattedHeaders[header[0]] = header[1] )
    setHeaders(formattedHeaders)
  }

  return <div className="mb-3">
    <div className='px-4 py-3 flex justify-between items-center rounded-t-lg border border-1 border-[#46485D] relative'>
      {keyDisabled ? <div className="w-full text-wrap no-scrollbar overflow-x-scroll mr-2">{headerKey}</div> : <input type="text" disabled={keyDisabled} value={newKey} className="border-transparent bg-inherit mr-2 active:border-transparent w-full h-full z-10" onChange={(e) => setNewKey(e.target.value)} />}
      {keyDisabled ? <RiEditBoxLine className="cursor-pointer z-20" onClick={() => setKeyDisabled(!keyDisabled)} /> : <RiSaveLine className="cursor-pointer z-20" onClick={handleKeySave} />}
    </div>
    <div className="bg-[#08040A] p-4 flex justify-between rounded-b-lg border border-1 border-t-0 border-[#46485D]">
      {valueDisabled ? <div className="w-full text-wrap no-scrollbar overflow-x-scroll mr-2">{value}</div> : <input type="text" disabled={valueDisabled} value={value} className="border-transparent bg-inherit mr-2 active:border-transparent w-full h-full z-10" onChange={(e) => setValue(e.target.value)} />}
      {valueDisabled ? <RiEditBoxLine className="cursor-pointer z-20" onClick={() => setValueDisabled(!valueDisabled)} /> : <div className="flex gap-2">
        <RiSaveLine className="cursor-pointer z-20" onClick={handleValueSave} />
        <AiOutlineImport className="cursor-pointer z-20 text-[#D9509B]" onClick={() => setPopOpen({ value: true, type: 'header', headerKey })} />
      </div>}
    </div>
  </div>
}

export default HeaderRow;
