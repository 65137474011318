import { useState, useEffect } from "react";

const APICard = ({ api, index, unSelectedApis, currentPage }) => {
  const [selected, setSelected] = useState(true);

  useEffect(() => {
    if (selected && unSelectedApis.includes(api)) {
      setSelected(false)
    }
    else if (!selected && !unSelectedApis.includes(api)) {
      setSelected(true)
    }
  }, [currentPage])

  const getMethodClasses = (method) => {
    switch (method.toLowerCase()) {
      case "post":
        return "bg-[#816F33]/40 text-[#FCD34D]";
      case "get":
        return "bg-[#31744A]/40 text-[#4ADE80]";
      case "put":
        return "bg-[#2F5A83]/40 text-[#44A5FF]";
      case "delete":
        return "bg-[#804343]/40 text-[#F87171]";
      case "patch":
        return "bg-[#6B4FBB4D] text-[#96A7FF]"
    }
  };

  const handleUnSelect = () => {
      if(selected && !unSelectedApis.includes(api)){
        unSelectedApis.push(api)
      }
      else if(!selected && unSelectedApis.includes(api)){
        unSelectedApis.pop(api)
      }
      setSelected(!selected)
    console.log(unSelectedApis)
  }
  
  return (
    <div
      className={
        "bg-[#0F1011] border border-b-1 border-[#2E3138] border-t-0 flex p-4 items-center " +
        (index % 2 == 0 ? "border-r-1 border-l-0" : "border-x-0")
      }
    >
      <div
        className={
          "mr-4 rounded-md min-w-[80px] text-center py-2 text-sm font-semibold uppercase " +
          getMethodClasses(api.request.method)
        }
      >
        {api.request.method}
      </div>
      <div className="flex max-w-[80%] justify-between w-full items-center">
        <div className="overflow-x-hidden hover:overflow-visible">
          <div>{api.api_name}</div>
          <div className="text-[#B0B0B0] text-sm w-full">{String(api.request.path)}</div>
        </div>
        <input
          // style={{ marginRight: "5px" }}
          type="checkbox"
          id="apiSelected"
          name="apiSelected"
          checked={selected}
          onClick={handleUnSelect}
        />
      </div>
    </div>
  );
};

export default APICard;
