import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "./ChatBot.css";
import serverLogo from "./logo2.png";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { useSelector } from "react-redux";
import Loader from "../IntegrateSDK/CollectAPI/Loader/Loader";
import { useDispatch } from "react-redux";
import { fetchTC, fetchReqResByAPI, getAllAPIs } from "../../Actions/tcActions";
import Right from "../../icons/chevron-right.svg";
import Left from "../../icons/chevron-left.svg";
import { all } from "axios";
import APICard from "./APICard/APICard";
import { useAuth } from "../../AuthContext";

/*

The ChatBot component is a chat application that communicates with a server using websockets.
It uses the socket.io-client library to establish a connection with the server.
It uses the useState hook to manage the state of the messages.
It uses the useEffect hook to listen for messages from the server.
It contains a form with a textarea for the user to input messages.
It sends messages to the server when the user submits the form.
It displays the messages from the server and the user in the chat area.

*/
const CharBot = ({ setPageStatus }) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const { loading: userLoading } = useSelector((state) => state.user);
  const {logout} = useAuth();
  const apis = useSelector((state) => state.apis);
  const [input, setInput] = useState("");
  const [signature, setSignature] = useState(""); // New state for signature
  const [req, setReq] = useState(""); // New state for request
  const [isFirstMessage, setIsFirstMessage] = useState(true); // Track if it's the first message
  const [allApis, setAllApis] = useState(null);
  const [currentApiIndex, setCurrentApiIndex] = useState(0);
  const [socket, setsocketConection] = useState(null);
  const [selectedApi, setSelectedApi] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [enableMessage, setEnableMessage] = useState(false);


  useEffect(() => {
    const socket = io("https://api-sample-gen-7qxc7hlaka-uc.a.run.app");
    setsocketConection(socket);

    socket.on("message", (message) => {
      console.log("Received message:", message);
      setEnableMessage(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "Server",
          message: typeof message === 'string' ? message : message.message || JSON.stringify(message)
        },
      ]);
    });

    return () => {
      socket.off("message");
      socket.disconnect();
    };
  }, []);


  useEffect(() => {
    if (allApis && allApis?.length > 0) {
      let messageToSend;
      if (isFirstMessage) {
        console.log("Sending First Message ");
        const reqNames = allApis[0].api;
        console.log("reqNames", reqNames);
        messageToSend = { signature: true, req: reqNames, input: null }; // Include signature and req for the first message
        if (socket && reqNames) {
          socket.emit('message', messageToSend);
          console.log("Sending First Message to Server");
          setIsFirstMessage(false); // Update to indicate first message has been sent
        }
        console.log("isFirstMessage", isFirstMessage);
        console.log("messageToSend", messageToSend);
      } else {
        messageToSend = { input: input }; // Only include input for subsequent messages
      }
    }
  }, [allApis]);

  const sendMessage = (e) => {
    setEnableMessage(false);
    e.preventDefault();
    if (input.trim()) {
      const userMessage = { sender: "User", message: input };
      setMessages([...messages, userMessage]);

      // Create message object
      let messageToSend;
      if (isFirstMessage) {
        const reqNames = selectedApi;
        messageToSend = { signature: true, req: reqNames, input: null }; // Include signature and req for the first message
        setIsFirstMessage(false); // Update to indicate first message has been sent
      } else {
        messageToSend = { input: input }; // Only include input for subsequent messages
      }

      if (socket) {
        socket.emit('message', messageToSend); // Send the message object
      } else {
        console.log("Socket not connected");
      }
      setInput("");
    }
  };

  useEffect(() => {
    if (messages.includes("API processing complete") || messages.includes("Nothing else is possible")) {
      // remove the first element from the array
      const tempApis = allApis;
      tempApis.shift();
      setAllApis(tempApis);
    }
  }, [messages]);

  //scroll to bottom of chat box
  useEffect(() => {
    const chatBox = document.querySelector(".chat-box");
    if (chatBox) {
    chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // dispatch(requestNames("e7b769ef-b96d-4135-9a4c-ec9b489a639b"));
    dispatch(getAllAPIs());
  }, []);

  useEffect(() => {
    if (apis) {
      const tempApis = [];
      apis?.allApis?.apiNames?.forEach(api => {
        if ((api.source.request.method !== "GET") && (api.source.request.method !== "DELETE") && (api.source.sample_updated !== true)) {
          tempApis.push({ api });
        }
      });
      setAllApis(tempApis);

      if(apis?.allApis && tempApis.length === 0){
        setChatLoading(true);
        setTimeout(() => {
          setChatLoading(false);
          setPageStatus({ keyIntegrated: false, apiCollected: false, verificationCompleted: true });
        }, 2000);
      }
    }
  }, [apis]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(allApis?.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = allApis?.slice(
    startIdx,
    startIdx + itemsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleRemoveApiAndResetMessages = () => {
    setAllApis((prevApis) => {
      // Calculate the index of the first element of the current page in allApis
      const indexToRemove = startIdx;

      // Create a new array excluding the element at indexToRemove
      console.log("indexToRemove", indexToRemove);
      console.log(allApis[0]?.api?.name)
      const updatedApis = prevApis.filter((_, index) => index !== indexToRemove);
      if(allApis && allApis.length < 2){
        setPageStatus({ keyIntegrated: false, apiCollected: false, verificationCompleted: true });
      }
      return updatedApis;
    });

    setMessages([]); // Reset messages to an empty array
    setIsFirstMessage(true); // Reset isFirstMessage to true
    // If the current page becomes invalid (e.g., when removing the last item on a page), go to the previous page
    if (startIdx >= allApis?.length - itemsPerPage && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={materialDark}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  const handleLogout = async () => {
    await logout();
}

  //loading||userLoading? <Loader/>:
  return ( chatLoading ? <Loader type="Verifying APIs"/> : <div className="bg-[#080808] p-4 grid grid-cols-10 w-full gap-4">
    <div className="col-span-3 rounded-lg bg-[#17181A] text-white flex flex-col items-center px-8">
      <div className="text-center mb-8">
        Please provide more information when DevzeryAI prompts you more information on the APIs.
      </div>
      <div className="w-full flex flex-col mb-8">
        {currentApis &&
          currentApis.map((api, index) => (
            // <APICard key={api.api.id} item={api.api} index={index} />
            <APICard item={api.api} index={allApis[0]?.api?.id} />
          ))}
        <div className="flex items-center self-end">
          <div className="text-[#EFEFEF] mr-2 text-sm">
            {" "}
            {startIdx + 1 === allApis?.length
              ? startIdx + 1
              : (startIdx + itemsPerPage) >
                allApis?.length
              ? allApis?.length
              : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
            of {allApis?.length}
          </div>
          <div className="flex text-[#EFEFEF]">
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#3E4045] mr-3 px-2 py-1"
              onClick={handlePrevPage}
            >
              <img src={Left} />
            </div>
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#3E4045] p-2"
              onClick={handleNextPage}
            >
              <img src={Right} />
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => setPageStatus({ keyIntegrated: false, apiCollected: false, verificationCompleted: true })} className="bg-[#D9509B] border border-1 border-[#FF86BE] rounded-lg px-6 py-2 cursor-pointer">
        Confirm API verification
      </div>
    </div>
    <div className="col-span-7 flex flex-col justify-between items-center pb-8">
    <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] self-end my-4 mr-4 rounded min-w-fit px-4 py-1 cursor-pointer"onClick={handleLogout}>Log Out</div>
      <div className="chat-box w-full flex flex-col">
        {messages.map((msg, index) => {
          if (msg.message == "Nothing else is possible") { handleRemoveApiAndResetMessages(); };
          return (<div className={msg.sender === "Server" ? "flex items-center" : " self-end justify-end text-right"}>
            {msg.sender === "Server" ? <img
              src={serverLogo}
              alt="Server Logo"
              className="w-[30px] h-[30px] mr-2"
            /> : ""}
            <div
              key={index}
              className={`rounded-lg border border-1 border-[#2E3138] px-4 py-2.5 text-[#ffffff] max-w-fit bg-inherit ${msg.sender === "Server"
                  ? " self-start justify-start"
                  : " self-end justify-end text-right"
                }`}
            >
              {msg.sender === "Server" ? (
                <>
                  {typeof msg.message === 'string' ?
                    <ReactMarkdown
                      components={components}
                      remarkPlugins={[remarkGfm]}
                      className="markdown-content"
                    >
                      {msg.message}
                    </ReactMarkdown> : <ReactMarkdown>{"Not a string"}</ReactMarkdown>
                  }
                </>
              ) : (
                <strong>{msg.sender == "Server" ? msg.sender : ""}</strong>
              )}
              {msg.sender == "User" ? <p className="self-end justify-end text-right">{msg.message}</p> : " "}
            </div>
          </div>)
        })}
      </div>
      <form
        className="bg-[#17181A] border border-1 border-[#2E3138] flex items-center p-2 w-[80%] rounded-md"
        onSubmit={sendMessage}
      >
        <textarea
          value={input}
          className="bg-inherit text-[#ffffff] flex items-center w-[90%]"
          placeholder="Enter your message..."
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              sendMessage(e);
            }
          }}
          disabled={!enableMessage}
        />
        <button
          className="px-2 py-1 bg-[#D9509B] rounded-md w-[10%] text-white"
          type="submit"
          disabled={!enableMessage}
        >
          Send
        </button>
      </form>
    </div>
  </div>
    
  );
};

export default CharBot;
