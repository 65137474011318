import React from 'react'
import './NavBtn.css'
import { Link,useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { logoutUser } from '../../../Actions/userActions';
import { Typography, IconButton } from '@mui/material'
import { useAuth } from '../../../AuthContext';

/*

NavBtn component that renders the navigation button for the navbar.
It displays the name of the button and the icon associated with it.
It also contains a link to the specified URL.
User can logout by clicking on the logout button.

*/
const NavBtn = ({ name, icon, url, isSelected }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { logout, user } = useAuth();

    const handleLogout = async (e) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        await logout();
        navigate('/login');
    };

    const buttonProps = url === '/logout' ? { onClick: handleLogout } : {};

    return (
        <div>
            <Link className={`navLink${isSelected ? '-selected' : ''}`} to={`${url}`}>
                <IconButton color='inherit' {...buttonProps}>
                    <img src={icon} className='navIcon' alt="logo" />
                    <Typography variant='h6'>{name}</Typography>
                </IconButton>
            </Link>
        </div>
    )
}

export default NavBtn
