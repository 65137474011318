import React from 'react'
import './EndpointCoverage.css'
import PieChart from '../../icons/pie-chart.png'
import { Typography } from '@mui/material'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import TestStatus from './TestStatus/TestStatus';

Chart.register(ArcElement, Tooltip, Legend, Title); // Create a chart with the necessary elements

/*

The EndpointCoverage component renders the test coverage statistics for the endpoint.
It displays the number of tests passed and failed in a doughnut chart.
It uses the Doughnut component from react-chartjs-2 to render the chart.
The component also displays the number of tests passed and failed.
The component takes in the passed and fail props to render the test coverage statistics.

*/
const EndpointCoverage = ({ passed, fail }) => {

    const empty=passed>=0 && fail>=0?false:true;

    // Create a data object with the passed and failed tests
    const data = {
        labels: ['Tests Passed', 'Tests Failed'],
        datasets: [{
            data: [passed, fail],
            backgroundColor: [
                'rgba(242,123,179,255)',
                'rgba(151,167,255,255)',
            ],
            borderColor: [
                'rgba(242,123,179,255)',
                'rgba(151,167,255,255)',
            ],
            hoverOffset: 4,
        }],
        radius: 60,

    };

    // Chart design options
    const chartOptions = data != null ? {
        cutout: '75%',
        plugins: {
            tooltip: {
                backgroundColor: (context) => {
                    const label = context.tooltip.dataPoints[0].label || '';
                    switch (label) {
                        case 'Tests Passed':
                            return '#533543';
                        case 'Tests Failed':
                            return '#3B4056';
                        default:
                            return '#fff';
                    }
                },
                displayColors: false,
                titleColor: (context) => {
                    const label = context.tooltip.dataPoints[0].label || '';
                    switch (label) {
                        case 'Tests Passed':
                            return '#F37BB3';
                        case 'Tests Failed':
                            return '#96A7FF';
                        default:
                            return '#fff';
                    }
                },
                callbacks: {
                    label: function (context) {
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${percentage}%`;
                    }
                }
            },
            legend: {
                display: false, // Hides the labels on top of the chart
            },
        },
        aspectRatio: 2.5,
        elements: {
            arc: {
                hoverRadius: 0, // Adjust this value to control the size of the label square when hovering
                borderWidth: 0.01, // Removes the border of the label square
            }
        }
    } : null;


    return (
        <div className={`endpointCoverage${empty?'-empty':""}`}>
            <Typography variant='h5' className='endpointCoverage-title'>
                Test Coverage
            </Typography>
            {passed>=0 && fail>=0 ? <div className='httpChart'>
                <Doughnut
                    data={data}
                    options={chartOptions}
                />
                <div className="absolute top-[40%] font-light text-[#BA76FF] left-1/2 -translate-x-1/2 text-xs text-center">Total tests <div className='text-white font-regular'>{passed+fail}</div></div>
            </div> :
                <>
                    <img src={PieChart} alt='Pie Chart' className='httpCoverage-image' />
                    <Typography variant='h6'>
                        Whoops! No data<br></br> available at the moment.
                    </Typography>
                </>
            }
            {data != null && passed>=0 && fail>=0 ? <div className='testsContainer'>
                <TestStatus
                    status={"Passed"}
                    val={passed}
                />
                <TestStatus
                    status={"Failed"}
                    val={fail}
                />
            </div> : null}
        </div>
    )
}

export default EndpointCoverage
