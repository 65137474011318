import React, { useState } from 'react';
import './ReqResBody.css';
import { Typography, Alert, Snackbar } from '@mui/material';
import EditIcon from '../../icons/edit-icon.svg';
import CopyIcon from '../../icons/copy-icon.svg';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

// The ReqResBody component displays a JSON string, allows copying it to the clipboard, and shows a notification.
const ReqResBody = ({ title, string, status }) => {
    // Initialize the parsed string based on the title.
    let parsedString = string;
    if (title === 'Request') {
        parsedString = JSON.stringify(string ?? {}, null, 2);
    }

    // useState hooks to manage the text state and the copied state.
    const [text, setText] = useState(parsedString);
    const [copied, setCopied] = useState(false);

    // Function to copy the parsed string to the clipboard and show the snackbar.
    const copyToClipboard = () => {
        navigator.clipboard.writeText(parsedString);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    // The component's JSX structure.
    return (
        <div className='req-res-container'>
            <div className="req-res-top">
                <Typography variant='h6'>{title}</Typography>
                <div className="edit-copy">
                    {/* Uncomment this line if you want to use the edit icon */}
                    {/* <img className='edit-icon' src={EditIcon} alt="edit icon" /> */}

                    <img
                        onClick={copyToClipboard}
                        className='copy-icon'
                        src={CopyIcon} alt="copy icon" />
                </div>
            </div>
            <div className="req-res-bottom">
                {title == 'Request' ? "" : <div className={"rounded-sm px-2 py-1 self-end justify-self-center " + (status < (300) ? "bg-[#31744A]" : "bg-[#633238]")}>{status}</div>}
                <div className="req-res-body">
                    <JSONPretty
                        style={{
                            overflowY: 'auto',    // Keep scrolling functionality but hide scrollbar
                            height: "100%"
                        }}
                        id="json-pretty"
                        themeClassName='custom-json-pretty'
                        keyStyle='color: #E45799;'
                        data={text}>
                    </JSONPretty>
                </div>
            </div>
            <Snackbar
                open={copied}
                autoHideDuration={1000}
                onClose={() => setCopied(false)}
            >
                <Alert severity="success">Copied to clipboard</Alert>
            </Snackbar>
        </div>
    );
};

export default ReqResBody;
