import React, { useEffect, useState, useRef } from "react";
import "./AddTC.css";
import AddTCSidebar from "./AddTCSidebar/AddTCSidebar";
import { Typography, Snackbar, Alert, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../Navbar/Navbar";
import {
  addTestCase,
  fetchReqResByAPI,
  getAllAPIs,
} from "../../Actions/tcActions";
import JSONPretty from "react-json-pretty";
import APISequence from "./APISequence/APISequence";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import Loader from "../Loader/Loader";
import Button from "@mui/material/Button";
import { fetchTC } from "../../Actions/tcActions";
import { useAuth } from "../../AuthContext";
import { PiCopy } from "react-icons/pi";
import ReqResDisplay from "./ResResDisplay/ReqResDisplay";
/*

AddTC component that renders the Add Test Case page of the application.
It uses the useNavigate hook from react-router-dom to navigate to different routes.
It contains a form to add a new test case.
The form includes fields for Test Case Title, Priority, and API Sequence.
It also includes a button to add a new API sequence.

*/

const AddTC = () => {
  const [allApis, setAllApis] = useState([]);
  const [currentApi, setCurrentApi] = useState(null);
  const [addedApis, setAddedApis] = useState({});
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [baseUrl, setBaseUrl] = useState(null);
  const [reqBody, setReqBody] = useState({});
  const [reqBodyDisabled, setReqBodyDisabled] = useState(true);
  const [resContent, setResContent] = useState({});
  const [resContentDisabled, setResContentDisabled] = useState(true);
  const [headers, setHeaders] = useState({});
  const [extra, setExtra] = useState({});
  const [extraRes, setExtraRes] = useState({});
  const [popOpen, setPopOpen] = useState({ value: false, ind: 0, type: 'req' });
  const [show, setShow] = useState(false)
  const [importAPI, setImportAPI] = useState('default');
  const [selected, setSelected] = useState({ value: '', ind: 0 })
  const [newLine, setNewLine] = useState(false)
  const anchorEl = useRef(null);
  const resAnchorEl = useRef(null);
  const headerEl = useRef(null);
  const resRef = useRef(null);
  const reqRef = useRef(null);

  const navigate = useNavigate();

  const { loading: useLoading, user } = useSelector((state) => state.user); // Get the user from the state
  const {
    allApis: apis,
    loading,
    message,
    error,
  } = useSelector((state) => state.apis); // Get the list of apis from the state

  const { apikey } = useAuth();

  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    // dispatch(fetchReqResByAPI(user?._id))
    dispatch(getAllAPIs());
  }, []);

  useEffect(() => {
    if (apis) {
      const tempApis = [];
      apis?.apiNames?.forEach((api) => {
        tempApis.push(api);
      });
      setAllApis(tempApis);
    }
  }, [apis]);

  const handleAddTC = () => {
    console.log("hi pushed", addedApis);
    const formattedApis = [];
    for (let [ind, api] of Object.entries(addedApis)) {
      formattedApis.push({
        name: api.name,
        baseUrl,
        endpoint: api.source.request.path,
        method: api.source.request.method,
        body: api.source.request.body ?? null,
        headers: headers ? headers : api.source.request.headers,
        expectedStatus: 200,
        response: api?.source?.response,
      });
    }
    console.log(formattedApis);
    try {
      dispatch(addTestCase({
        name: title,
        description: description,
        apis: formattedApis,
        apikey: apikey
      }))
    } catch (error) {
      // On error
      setAlertSeverity("error");
      setAlertMessage("Failed to Add Test Case");
      setSnackbarOpen(true);
    } finally {
      setAlertSeverity("success");
      setAlertMessage("Successfully Added Test Case");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (message) {
      dispatch(fetchTC());
      navigate("/test-suite");
    }
    if (error) {
      console.log(error);
    }
  }, [message, error]);

  useEffect(() => {
    if (reqBodyDisabled && addedApis[0]) {
      for (let [ind, value] of Object.entries(addedApis)) {
        if (value.id == currentApi.id) {
          addedApis[ind] = {
            ...value,
            source: {
              ...value.source,
              request: {
                ...value.source.request,
                body:
                  typeof reqBody === "string" && reqBody !== "NA"
                    ? JSON.parse(reqBody)
                    : {},
              },
            },
          };
        }
      }
    }
  }, [reqBodyDisabled]);
  useEffect(() => {
    if (resContentDisabled && addedApis[0]) {
      for (let [ind, value] of Object.entries(addedApis)) {
        if (value.id == currentApi.id) {
          addedApis[ind] = {
            ...value,
            source: {
              ...value.source,
              response: {
                ...value.source.response,
                content:
                  typeof resContent === "string" && resContent !== "NA"
                    ? JSON.parse(resContent)
                    : {},
              },
            },
          };
        }
      }
    }
  }, [resContentDisabled]);

  const handleReqSave = () => {
    const temp = Array.from(reqRef.current.childNodes)
      .flatMap((node) => Array.from(node.childNodes))
      .filter((node) => !node?.classList?.contains("ignore"))
      .map((node) => node.textContent ?? node.innerText)
      .join("");
    let parsed_temp = {};
    try {
      parsed_temp = JSON.parse(temp);
      setReqBody(JSON.stringify(parsed_temp, null, 4));
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("SyntaxError: Expected ',' or '}' after property value in JSON");
      setSnackbarOpen(true);
    }
    setExtra({});
    setReqBodyDisabled(!reqBodyDisabled);
  };

  const handleResSave = () => {
    const temp = Array.from(resRef.current.childNodes)
      .flatMap((node) => Array.from(node.childNodes))
      .filter((node) => !node?.classList?.contains("ignore"))
      .map((node) => node.textContent ?? node.innerText)
      .join("");
    console.log(temp)
    let parsed_temp = {};
    try {
      parsed_temp = JSON.parse(temp);
      setResContent(JSON.stringify(parsed_temp, null, 4));
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("SyntaxError: Expected ',' or '}' after property value in JSON");
      setSnackbarOpen(true);
    }
    setExtraRes({});
    setResContentDisabled(!resContentDisabled);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
    setAlertSeverity("success");
    setAlertMessage("Copied to Clipboard");
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 1000);
  };

  const handleImport = () => {
    if (popOpen.type == 'header') {
      if (popOpen.headerKey) {
        setHeaders({ ...headers, [popOpen.headerKey]: selected.value[1] });
      } else {
        setHeaders({ ...headers, [selected.value[0]]: selected.value[1] });
      }
    } else if (popOpen.type == 'req') {
      let temp = { ...extra };
      temp[popOpen.ind] = typeof (selected.value[1]) == 'string' || typeof (selected.value[1]) == 'boolean' || typeof (selected.value[1]) == 'number' ? `"${selected.value[1]}"` : JSON.stringify(selected.value[1]);
      setExtra(temp);
    } else {
      let temp = { ...extraRes };
      temp[popOpen.ind] = typeof (selected.value[1]) == 'string' || typeof (selected.value[1]) == 'boolean' || typeof (selected.value[1]) == 'number' ? `"${selected.value[1]}"` : JSON.stringify(selected.value[1]);
      setExtraRes(temp);
    }
    setPopOpen({ ...popOpen, value: false });
  }

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <div className="w-full grid grid-cols-10 gap-8 overflow-hidden text-white">
      <div className="col-span-2">
        <Navbar />
      </div>
      <div className="col-span-6">
        <div className="w-full flex gap-3 justify-end items-center my-4">
          <Button
            variant="contained"
            sx={{
              "&.Mui-disabled": {
                background: "#222222",
                color: "white",
                border: "1px solid #222222",
              },
              backgroundColor: "#D9509B",
              "&:hover": {
                backgroundColor: "#C0408C",
              },
              minWidth: "fit-content",
              px: 2,
              py: 1,
              textTransform: "unset",
              fontWeight: "400",
            }}
            disabled={!title || !description || !baseUrl || !addedApis[0]}
            onClick={handleAddTC}
          >
            Add Test Case
          </Button>
          <div
            onClick={() => {
              navigate("/test-suite");
            }}
            className="bg-[#21222d] px-4 py-2 rounded-md cursor-pointer"
          >
            Cancel
          </div>
        </div>
        <div className="w-full grid grid-cols-7 gap-6">
          <div className="col-span-4 flex flex-col gap-4">
            <div className="tc-title">
              <Typography variant="h6">
                Test Case Title <span className="text-[#D9509B]">*</span>
              </Typography>
              <div className="tc-desc">
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Test Case Title"
                ></input>
              </div>
            </div>
            <div className="tc-title">
              <Typography variant="h6">
                Test Case Description <span className="text-[#D9509B]">*</span>
              </Typography>
              <div className="tc-desc">
                <textarea
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Test Case Description"
                ></textarea>
              </div>
            </div>
            <div className="tc-title">
              <Typography variant="h6">
                Test Case Base URL <span className="text-[#D9509B]">*</span>
              </Typography>
              <div className="tc-desc">
                <input
                  onChange={(e) => setBaseUrl(e.target.value)}
                  placeholder="Enter Test Case Base URL"
                ></input>
              </div>
            </div>
            <div className="api-seq">
              <Typography variant="h6">Add API Sequence</Typography>
              <APISequence
                setHeaders={setHeaders}
                setResContent={setResContent}
                setReqBody={setReqBody}
                allApis={allApis}
                setCurrentApi={setCurrentApi}
                addedApis={addedApis}
                currentApi={currentApi}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 col-span-3">
            <div className="tc-priority">
              <Typography variant="h6">
                Priority <span className="text-[#D9509B]">*</span>
              </Typography>
              <div className="priority-select">
                <select>
                  <option style={{ color: "#F87171" }}>High</option>
                  <option style={{ color: "#FCD34D" }}>Medium</option>
                  <option style={{ color: "#44A5FF" }}>Low</option>
                </select>
              </div>
            </div>
            <div className="rounded-md w-full">
              <div className="bg-[#21222D] flex justify-between items-center p-3">
                Request
                <div className="flex gap-2 items-center">
                  {reqBodyDisabled ? (
                    <RiEditBoxLine
                      className="cursor-pointer"
                      onClick={() => setReqBodyDisabled(!reqBodyDisabled)}
                    />
                  ) : (
                    <RiSaveLine
                      className="cursor-pointer"
                      onClick={handleReqSave}
                    />
                  )}
                  <PiCopy
                    className="cursor-pointer"
                    onClick={() => copyToClipboard(reqBody)}
                  />
                </div>
              </div>
              <div className="bg-[#08040A] min-h-[25vh] max-h-[30vh] p-3 overflow-scroll no-scrollbar relative">
                {reqBodyDisabled ? (
                  <JSONPretty
                    id="json-pretty"
                    themeClassName="text-sm"
                    keyStyle="color: #E45799;"
                    data={reqBody ?? "NA"}
                  ></JSONPretty>
                ) : (
                  <div
                    ref={reqRef}
                    className="p-3 w-full min-h-[25vh] max-h-[30vh] bg-inherit focus:outline-none absolute top-0 left-0 z-20"
                    disabled={reqBodyDisabled}
                    type="text"
                  >
                    {reqBody.split("\n").map((line, index) => <ReqResDisplay
                        index={index}
                        line={line}
                        show={show}
                        setShow={setShow}
                        reqBody={reqBody}
                        setReqBody={setReqBody}
                        anchorEl={anchorEl}
                        extra={extra}
                        setPopOpen={setPopOpen}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="rounded-md w-full">
              <div className="bg-[#21222D] p-3 flex justify-between items-center">
                Response
                <div className="flex gap-2 items-center">
                  {resContentDisabled ? (
                    <RiEditBoxLine
                      className="cursor-pointer"
                      onClick={() => setResContentDisabled(!resContentDisabled)}
                    />
                  ) : (
                    <RiSaveLine
                      className="cursor-pointer"
                      onClick={handleResSave}
                    />
                  )}
                  <PiCopy
                    className="cursor-pointer"
                    onClick={() => copyToClipboard(resContent)}
                  />
                </div>
              </div>
              <div className="bg-[#08040A] min-h-[25vh] overflow-scroll no-scrollbar max-h-[30vh] p-3 relative">
                {resContentDisabled ? (
                  <JSONPretty
                    id="json-pretty"
                    themeClassName="text-sm"
                    keyStyle="color: #E45799;"
                    data={resContent ?? "NA"}
                  ></JSONPretty>
                ) : (
                  <div
                    ref={resRef}
                    className="p-3 w-full h-[30vh] bg-inherit focus:outline-none absolute top-0 left-0 z-20"
                    disabled={resContentDisabled}
                    type="text"
                  >
                    {resContent.split("\n").map((line, index) => <ReqResDisplay
                        index={index}
                        line={line}
                        show={show}
                        setShow={setShow}
                        resContent={resContent}
                        setResContent={setResContent}
                        resAnchorEl={resAnchorEl}
                        extraRes={extraRes}
                        setPopOpen={setPopOpen}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <Popover
              open={popOpen.value}
              onClose={() => setPopOpen({ ...popOpen, value: false })}
              anchorEl={popOpen.type == 'req' ? anchorEl.current : popOpen.type == 'res' ? resAnchorEl.current : headerEl.current}
              className="no-scrollbar"
              slotProps={{
                paper: {
                  style: {
                    width: "30vw",
                    backgroundColor: "#21222D",
                    color: "#fff",
                    boxShadow: "none",
                    borderRadius: "12px",
                    border: "1px solid #3D3D3D",
                    padding: "1rem",
                    maxHeight: "50vh",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    }
                  },
                }
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: popOpen.type == 'res' ? "bottom" : "top",
                horizontal: "left",
              }}
              anchorPosition={
                {
                  top: 0,
                  left: -500
                }
              }
            >
              <div className="font-semibold my-2">Select API</div>
              <select className="bg-inherit px-3 py-2 py-0.5 rounded-sm border border-gray-200" value={importAPI} onChange={(e) => setImportAPI(e.target.value)}><option disabled="disabled" value={'default'}>Select an API</option>{Object.keys(addedApis).map((key) => key == Object.keys(addedApis).length-1 ? null : <option value={key}>{addedApis[key].name}</option>)}</select>
              {importAPI != 'default' ? <div className="font-semibold my-2">{popOpen.type == 'header' ? "Headers" : "Response Content"}</div> : null}
              {importAPI != 'default' ? <div className="p-3 rounded-md bg-[#171821]">{popOpen.type == 'header' ? Object.entries(addedApis[importAPI].source.request.headers).map((el, ind) => <div>
                <div className={"bg-[#21222d] px-1 py-0.5 text-wrap break-words my-1 max-w-fit cursor-pointer " + (ind == selected.ind ? " border border-[#D9509B]" : "")} onClick={() => setSelected({ value: el, ind })}>{el[0]}: {typeof (el[1]) == 'string' || typeof (el[1]) == 'boolean' || typeof (el[1]) == 'number' ? el[1] : JSON.stringify(el[1])}
                </div>
              </div>) : Object.entries(addedApis[importAPI].source.response.content).map((el, ind) => <div>
                <div className={"bg-[#21222d] px-1 py-0.5 text-wrap break-words my-1 max-w-fit cursor-pointer " + (ind == selected.ind ? " border border-[#D9509B]" : "")} onClick={() => setSelected({ value: el, ind })}>{el[0]}: {typeof (el[1]) == 'string' || typeof (el[1]) == 'boolean' || typeof (el[1]) == 'number' ? el[1] : JSON.stringify(el[1])}
                </div>
              </div>)}</div> : null}
              <div className={"px-3 py-1 rounded-md max-w-fit self-end justify-self-end mt-4 " + (importAPI == 'default' ? "bg-[#222222]" : "bg-[#D9509B] hover:bg-[#C0408C] cursor-pointer")} onClick={importAPI == 'default' ? ()=> console.log("") : handleImport}>Import</div>
            </Popover>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <AddTCSidebar setPopOpen={setPopOpen} headerEl={headerEl} headers={headers ?? {}} setHeaders={setHeaders} />
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default AddTC;
