import React, { useEffect, useState } from "react";
import "./IndividualAPI.css";
import Sidebar from "../Sidebar/Sidebar";
import { Dialog, Typography, duration } from "@mui/material";
import ArrowLeft from "../../icons/arrow-left.svg";
import Clock from "../../icons/clock.svg";
import StopWatch from "../../icons/stopwatch.svg";
import Calendar from "../../icons/calendar.svg";
import StatusBtn from "../IssuesTable/StatusBtn/StatusBtn";
import TypeBtn from "../TypeBtn/TypeBtn";
import APISidebar from "../APISidebar/APISidebar";
import ReqResBody from "../ReqResBody/ReqResBody";
import EditIcon from "../../icons/edit-icon.svg";
import DeleteIcon from "../../icons/delete-icon.svg";
import PlusIcon from "../../icons/plus-icon.svg";
import {
  fetchAllTC,
  fetchIndividualAPI,
  fetchIndividualAssertions,
  getAllAPIs,
} from "../../Actions/tcActions";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../Navbar/Navbar";
import Loader from "../Loader/Loader";

/*

IndividualAPI component that renders the individual API page of the application.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useParams hook from react-router-dom to access the ID parameter.
It uses the useEffect hook to fetch the individual API and assertions when the component mounts.
It renders the ReqResBody component to display the request and response body of the API.
It renders the StatusBtn component to display the status of the API.
It renders the TypeBtn component to display the type of the API.
It renders the APISidebar component to display the sidebar with API details.

*/
const IndividualAPI = () => {
  const dispatch = useDispatch();
  const [dependencies, setDependenies] = useState([])
  const { id } = useParams();
  const { apis, individualAPI, loading } = useSelector(
    (state) => state.apis
  ); // Load individual API from the state
  const { assertions, loading: assertionLoading } = useSelector(
    (state) => state.assertions
  ); // Load assertions from the state
  const { testCases } = useSelector(
    (state) => state.testCases
  ); // Load individual API from the state

  // Create a list of assertions to display
  let data = [];
  let pass = 0;
  let fail = 0;
  if (assertions) {
    assertions?.data.forEach((assertion) => {
      if (assertion.status === "pass") pass++;
      else fail++;
      data.push({
        id: assertion._id,
        name: assertion.title,
        status: assertion.status,
        duration: assertion.duration,
        pass: assertion.pass === true ? "pass" : "pass",
      });
    });
  }
  let headers = [];
  let header = individualAPI?.data?.assertions?.request_headers;
  for (let key in header) {
    headers.push({ key, value: header[key] });
  }

  const [testModal, setTestModal] = useState(false);
  const [headerModal, setHeaderModal] = useState(false);

  const handleTestOpen = () => {
    setTestModal(true);
  };
  const handleTestClose = () => {
    setTestModal(false);
  };
  const handleHeaderOpen = () => {
    setHeaderModal(true);
  };
  const handleHeaderClose = () => {
    setHeaderModal(false);
  };

  useEffect(() => {
    dispatch(fetchIndividualAPI(id)); // Fetch the individual API corresponding to the ID
  }, []);

  let testCase = {}
  if (testCases) {
    testCase = testCases?.tests.filter(test => test.id == individualAPI?.data?.parentSuiteId)
  }
  useEffect(() => {
    if (individualAPI && individualAPI?.data) {
      dispatch(fetchAllTC(individualAPI?.data?.parentSuiteId))
    }
  }, [individualAPI])

  
useEffect(() => {
  if (apis && apis?.data) {
    const newDependencies = [...dependencies]; // Clone the current state to avoid mutation

    apis.data.forEach(api => {
      if (api?.api?.step < individualAPI?.data?.step) {
        // Check if the dependency already exists
        if (!newDependencies.some(dep => dep.title === api?.api?.title)) {
          newDependencies.push(api.api); // Add new dependency
        }
      }
    });

    // Only update state if new dependencies were added
    if (newDependencies.length !== dependencies.length) {
      setDependenies(newDependencies); // Update state with the new array
    }
  }
}, [apis, individualAPI, dependencies]);

  let totalPass = 0;
  let totalFail = 2;
  if (
    individualAPI?.data?.assertions?.status.pass
  ) {
    totalPass += 1;
    totalFail -= 1;
  }
  if (
    individualAPI?.data?.assertions?.response?.pass
  ) {
    totalPass += 1;
    totalFail -= 1;
  }

  return loading == true ? (
    <Loader loading={loading} />
  ) : (
    <div className="w-full grid grid-cols-10 overflow-hidden">
      <div className="col-span-2">
        <Navbar />
      </div>
      <div className="text-white col-span-6 flex flex-col justify-start px-8">
        <div className="back-arrow">
          <div className="arrow-icon" onClick={() => window.history.back()}>
            <img src={ArrowLeft} alt="arrow-left" />
          </div>
          <div className="time-info">
            <div className="calendar-div">
              <img className="time-info-icon" src={Calendar} />
              <p>{testCase[0]?.date}</p>
            </div>
            <div className="clock-div">
              <img className="time-info-icon" src={Clock} />
              <p>{testCase[0]?.time}</p>
            </div>
            <div className="watch-div">
              <img className="time-info-icon" src={StopWatch} />
              <p>{individualAPI?.data?.duration/1000} secs</p>
            </div>
          </div>
        </div>
        <div className="api-name-assertion">
          <Typography variant="h6">{individualAPI?.data?.title}</Typography>
          <div className="add-assertion" style={{ marginRight: "2vh" }}>
            {/* <button className='add-assertion-btn' onClick={handleTestOpen}>Add Test Assertion</button> */}
          </div>
          <Dialog
            open={testModal}
            onClose={handleTestClose}
            PaperProps={{
              style: {
                height: "549px",
                width: "586px",
                backgroundColor: "#21222D",
                color: "#fff",
                boxShadow: "none",
                borderRadius: "12px",
                border: "1px solid #3D3D3D",
              },
            }}
          >
            <div className="baseUrlModalContainer">
              <Typography sx={{ fontFamily: "Roboto", fontWeight: "400" }}>
                Test Assertion
              </Typography>
              <input
                placeholder="Enter the assertion title"
                className="assertion-title-input"
              />
              <Typography>API End Point</Typography>
              <div className="endpoint-method-url2">
                <div className="method-select">
                  <select>
                    <option value="" selected>
                      POST
                    </option>
                    <option value="">GET</option>
                    <option value="">PUT</option>
                    <option value="">DELETE</option>
                  </select>
                </div>
                <div className="url-select">
                  <select className="url-select">
                    <option value="" selected disabled>
                      URL
                    </option>
                  </select>
                </div>
              </div>
              <div className="assertion-type-target">
                <div className="assertion-type">
                  <Typography>Assertion Type</Typography>
                  <select>
                    <option value="" selected disabled>
                      Status Code
                    </option>
                  </select>
                </div>
                <div className="assertion-target">
                  <Typography>Target</Typography>
                  <select name="" id="">
                    <option value="" selected disabled>
                      Find Value
                    </option>
                  </select>
                </div>
              </div>
              <div className="dependencies-status">
                <div className="assertion-dependency">
                  <Typography>Dependencies</Typography>
                  <select>
                    <option value="" selected disabled>
                      Select the first dependency API
                    </option>
                  </select>
                </div>
                <div className="assertion-status">
                  <Typography>Status</Typography>
                  <select>
                    <option value="" selected>
                      Passed
                    </option>
                    <option value="">Failed</option>
                  </select>
                </div>
              </div>
              <div className="plusBtn">
                <img src={PlusIcon} alt="plus-icon" />
              </div>
              <div className="addCancel">
                <button className="addBtn">Add</button>

                <button className="cancelBtn" onClick={handleTestClose}>
                  Cancel
                </button>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="my-8">
          <Typography variant="h6">API End Point</Typography>
          <div className="flex justify-between">
            <div className="flex">
              <div className="bg-[#21222d] px-4 py-2 mr-2">
                {individualAPI?.data?.method}
              </div>
              <div className="bg-[#21222d] px-4 py-2 mr-2">
                {individualAPI?.data?.path}
              </div>
            </div>
            <div className="bg-[#21222d] px-4 py-2" onClick={handleHeaderOpen}>
              View Headers
            </div>
          </div>
          <Dialog
            open={headerModal}
            onClose={handleHeaderClose}
            PaperProps={{
              style: {
                height: "549px",
                width: "586px",
                backgroundColor: "#21222D",
                color: "#fff",
                boxShadow: "none",
                borderRadius: "12px",
                border: "1px solid #3D3D3D",
                overflow: "hidden",
              },
            }}
          >
            <div className="baseUrlModalContainer">
              <Typography sx={{ fontFamily: "Roboto", fontWeight: "400" }}>
                API End Point
              </Typography>
              <div className="api-method-url">
                <div className="api-method">{individualAPI?.data?.method}</div>
                <div className="api-url">{individualAPI?.data?.path}</div>
              </div>
              <Typography>Headers</Typography>
              <div className="flex flex-col max-h-[60%] mt-2 overflow-y-scroll no-scrollbar gap-2">
                {headers.length > 0 &&
                  headers.map((header) => (
                    <div className="header-row">
                      <div className="body-type">
                        <p>{header.key}</p>
                      </div>
                      <div className="body-val">
                        <p>{header.value}</p>
                      </div>
                    </div>
                  ))}
              </div>
              {/* <div className="plusBtn">
                                <img src={PlusIcon} alt="plus-icon" />
                            </div> */}
              <div className="addCancel">
                {/* <button className='addBtn'>Add</button> */}

                <button className="cancelBtn" onClick={handleHeaderClose}>
                  Cancel
                </button>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className=" max-h-[25vh]">
            <ReqResBody
              title={"Request"}
              string={individualAPI?.data?.assertions?.body}
              status=""
            />
          </div>
          <div className=" max-h-[25vh]">
            <ReqResBody
              title={"Expected Response"}
              string={individualAPI?.data?.assertions?.response.expected}
              status={individualAPI?.data?.assertions?.status.expected}
            />
          </div>
          <div className=" max-h-[25vh]">
            <ReqResBody
              title={"Actual Response"}
              string={
                individualAPI?.data?.assertions?.status.expected === 
                individualAPI?.data?.assertions?.status.actual
                  ? individualAPI?.data?.assertions?.response.actual
                  : individualAPI?.data?.assertions?.response.expected
              }
             status={(individualAPI?.data?.assertions?.status.expected === individualAPI?.data?.assertions?.status.actual) ? individualAPI?.data?.assertions?.status.expected : individualAPI?.data?.assertions?.status.expected}
            />
          </div>
        </div>
        <Typography
          variant="h6"
          sx={{
            margin: "4rem 0 0 0",
          }}
        >
          Test Assertions
        </Typography>
        <div className="flex items-center gap-8">
          <div className="test-table">
            <table className="test-assertions-table">
              <tr className="assertions-cols">
                <th className="assertions-header-id">ID</th>
                <th className="assertions-header">Assertions</th>
                <th className="pass-header">Status</th>
              </tr>
              <tbody>
                <tr
                  className="assertions-table-row"
                  style={{ backgroundColor: "#171821" }}
                >
                  <td className="assertions-name">1</td>
                  <td className="assertions-col">
                    {individualAPI?.data?.title} Status
                  </td>
                  <td className={`passed-col`}>
                    <TypeBtn
                      type={
                        individualAPI?.data?.assertions?.status.pass
                          ? "pass"
                          : "pass"
                      }
                      tests={
                        individualAPI?.data?.assertions?.status.pass
                          ? "Passed"
                          : "Passed"
                      }
                    />
                  </td>
                </tr>
                <tr
                  className="assertions-table-row"
                  style={{ backgroundColor: "#171821" }}
                >
                  <td className="assertions-name">2</td>
                  <td className="assertions-col">
                    {individualAPI?.data?.title} Response
                  </td>
                  <td className={`passed-col`}>
                    <TypeBtn
                      type={
                        individualAPI?.data?.assertions?.response?.pass
                          ? "pass"
                          : "pass"
                      }
                      tests={
                        individualAPI?.data?.assertions?.response?.pass
                          ? "Passed"
                          : "Passed"
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="bg-[#21222d] px-8 py-2 mb-3 text-center">
              <Typography sx={{ color: "#4ADE80" }}>Passed</Typography>
              {2}
            </div>
            <div className="bg-[#21222d] px-2 py-1 text-center">
              <Typography sx={{ color: "#F87171" }}>Failed</Typography>
              {0}
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <APISidebar isBugs={false} api={individualAPI?.data} dependencies={dependencies} />
      </div>
    </div>
  );
};

export default IndividualAPI;