import React, { Fragment, useState } from "react";
import "./APITable.css";
import IssuesTableDropdown from "../IssuesTable/IssuesTableDropdown/IssuesTableDropdown";
import { Typography } from "@mui/material";
import StatusBtn from "../IssuesTable/StatusBtn/StatusBtn";
import SortBtnMenu from "../IssuesTable/SortBtnMenu/SortBtnMenu";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import NoData from '../../icons/no-data.png'

/*

APITable component that renders the API table on the API overview page.
It displays the list of APIs in a table format.
It uses the IssuesTableDropdown, StatusBtn, and Method components.
It uses the useState hook to manage the state of the dialog.
It uses the useDispatch hook from react-redux to dispatch actions.
It uses the Link component from react-router-dom to navigate to different routes.
It contains buttons to sort and filter the APIs.

*/

const APITable = ({ prodId, isBugs, apis }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  //Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Pagination logic
  const totalPages = Math.ceil(apis?.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = apis.slice(startIdx, startIdx + itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  // console.log(apis)
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  // const handleStatusChange = async (bugId, newStatus) => {
  //    await dispatch(updateBugStatus(bugId, newStatus));
  //    await dispatch(getBugsByProductId(prodId))
  // };
  const handleClose = () => {
    setOpenDialog(false);
  };

  // Show or hide the sort menu
  const handleSortOpen = () => {
    setSortOpen(!sortOpen);
  };

  // Show or hide the filter menu
  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <div className="issueTableContainer">
      <div className="flex justify-between items-center w-full border border-1 rounded-t-lg border-[#313248] border-b-0 p-4">
        <div>APIs List</div>
        <div className="flex items-center">
          <input placeholder="Search" className="searchTC" />
          <button className="sortBtn" onClick={handleSortOpen}>
            Sort
          </button>
          {sortOpen && <SortBtnMenu />}
          <button className="filterBtn" onClick={handleFilterOpen}>
            Filter
          </button>
          {filterOpen && <SortBtnMenu />}
          {!isBugs && <button className="runAllBtn">Run All</button>}
        </div>
      </div>

      {apis?.length > 0 ? <Fragment>
        <table>
          <tr className="colNames">
            <th>API Name</th>
            <th>Assertions</th>
            <th>Passed</th>
            <th className="priorityTh">Failed</th>
            <th className="actionTh">Action</th>
          </tr>
          {currentApis.map((api) => (
            <tr key={api._id}>
              <td className="apiIdCol" style={{ color: "white" }}>
                {/* <Method method={api.api.source.request.method} screen={'api'}/> */}
                {/* <Link
                                   to={`/api/${api.api.id}`}
                                 > */}
                {api.api.name}
                {/* </Link> */}
              </td>
              <td className="testNameCol">
                <StatusBtn
                  isAPI={true}
                  // status={bug.assertions}
                  color={"#5A628F"}
                />
              </td>
              <td className="statusCol">
                <StatusBtn
                  isAPI={true}
                  // status={bug.passed}
                  color={"#22C55E"}
                />
              </td>
              <td style={{ paddingLeft: "15px" }} className={`failed-api`}>
                <StatusBtn
                  isAPI={true}
                  // status={bug.failed}
                  color={"#673636"}
                />
              </td>
              <td className="actionsCol">
                <IssuesTableDropdown
                  editDetails={setOpenDialog}
                  id={api.id}
                  bug={api}
                  // prodId={prodId}
                />
              </td>
            </tr>
          ))}
        </table>
        <div className="btmRow">
          <div className="btmRow-right">
            <div className="resultsPerPage">
              <Typography sx={{ fontSize: "0.8vw" }}>Results per page</Typography>
              <div className="numberOfResults">
                <p>{itemsPerPage}</p>
              </div>
            </div>
            <div className="tableNaviation">
              <div className="tnText">
                <Typography sx={{ fontSize: "0.8vw" }}>
                  {startIdx + 1}-{Math.min(startIdx + itemsPerPage, apis.length)}{" "}
                  of {apis.length}
                </Typography>
              </div>
              <div className="flex items-center gap-2 ml-3">
                  <div className='flex items-center justify-center rounded-md border border-[#313248] p-1 cursor-pointer'
                      onClick={handlePrevPage}
                  ><HiChevronLeft /></div>
                  <div className='flex items-center justify-center rounded-md border border-[#313248] p-1 cursor-pointer'
                      onClick={handleNextPage}
                  ><HiChevronRight /></div>
              </div>
            </div>
          </div>
        </div>
      </Fragment> : <div className='w-full h-[50vh] border border-[#313248] rounded-t-none rounded-md flex flex-col justify-center items-center text-[#6E7089]'>
            <img src={NoData} />
            <div className='mt-4'>Generating and Executing your tests!</div> 
            <div className='flex items-center gap-2 '> This will be complete in a while.</div>
            </div>}
    </div>
  );
};

export default APITable;
