import { AiOutlineImport } from "react-icons/ai";

const ReqResDisplay = ({ index, line, setShow, show, setReqBody, reqBody, setResContent, resContent, anchorEl, resAnchorEl, extra, extraRes, setPopOpen }) => {
    return <div
        className={
            "bg-[#171821] mb-1 w-max py-0.5 pl-1 pr-2 whitespace-pre flex items-center"
        }
        key={index}
        onMouseEnter={() => setShow({ value: true, ind: index })} onMouseLeave={() => setShow({ value: false, ind: index })}
        contentEditable="true"
        onKeyDown={(e) => {
            if (e.key == "Enter") {
                let temp = reqBody ? reqBody.split("\n") : resContent.split("\n")
                temp[index] = temp[index] + '\n "key": "value",'
                setReqBody ? setReqBody(temp.join("\n")) : setResContent(temp.join("\n"))
            }
        }}
    >
        {line.includes(":") ? <>
            {line.split(":")[0] + ":"}{" "}
            {extra ? <span className={index in extra ? "text-pink-500" : ""} ref={anchorEl}>
                {index in extra ? extra[index] + "," : line.split(":").slice(1).join(":")}
            </span> : <span className={index in extraRes ? "text-pink-500" : ""} ref={resAnchorEl}>
                {index in extraRes ? extraRes[index] + "," : line.split(":").slice(1).join(":")}
            </span>}
        </> : line}
        {line.includes(":") && (show.value && show.ind == index) ? <span
            className="ignore rounded-md text-[#D9509B] py-0.5 px-1 bg-[#21222d] text-sm ml-4 cursor-pointer"
            onClick={() => setPopOpen({ value: true, ind: index, type: setReqBody ? 'req' : 'res' })}
        >
            <AiOutlineImport />
        </span> : null}
    </div>
};

export default ReqResDisplay;