import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Typography } from "@mui/material";
import { getBaseURLs, addBaseURL } from "../../../Actions/tcActions";
import BaseUrl from "../BaseUrl/BaseUrl";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PlusIcon from "../../../icons/plus-icon.svg";
import { PiUploadSimple, PiPlus } from "react-icons/pi";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";


const BaseUrlModal = ({ modalOpen, setModalOpen }) => {
  const [sources, setSources] = useState([]);
  const [sourceLength, setSourceLength] = useState(0);
  const [edit, setEdit] = useState(false);
  const[version, setVersion] = useState("version-1.2.5");
  const dispatch = useDispatch();

  const { loading, baseUrls } = useSelector((state) => state.apis);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (baseUrls && baseUrls.data) {
      setSources(baseUrls.data.urls);
      setSourceLength(baseUrls.data.urls.length);
    }
  }, [baseUrls]);

  const renderSources = () => {
    let arr = [];
    for (let i = 0; i < sourceLength; i++) {
      arr.push(
        <BaseUrl
          source={sources[i]}
          sources={sources}
          ind={i}
          sourceLength={sourceLength}
          setSources={setSources}
        />
      );
    }
    return arr;
  };

  const handleAddBaseURL = () => {
    console.log("add base url");
    console.log(sources);
    try {
      let cleansources = sources.map((source) => {
        if (source.baseUrl != "" && source.source != "") {
          return source;
        }
      });
      if (sourceLength > cleansources.length && cleansources == sources) { 
        setModalOpen(false);
        setSourceLength(cleansources.length);
        return
      }
      if (cleansources != sources) {
        dispatch(addBaseURL(sources));
        setModalOpen(false);
        setAlertSeverity("success");
        setAlertMessage("Base URL added successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log(error);
      setAlertSeverity("error");
      setAlertMessage("Error adding base URL");
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <Dialog
        className="base-url-dialog"
        open={modalOpen}
        PaperProps={{
          style: {
            height: "549px",
            width: "50vw",
            backgroundColor: "#17171A",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #2C2E33",
          },
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="p-6 flex flex-col">
            <div
              onClick={() => setModalOpen(false)}
              className="text-[#B0B0B0] self-end cursor-pointer"
            >
              <PiPlus className="rotate-45" size={24} />
            </div>
            <Typography
              sx={{ fontFamily: "Roboto", fontWeight: "400" }}
              variant="h6"
            >
              Version
            </Typography>
            <div className="px-3 py-2 flex items-center justify-between gap-2 bg-[#0F1011] rounded-md border border-[#2C2E33] mt-3 mb-6">
              <input
                placeholder="version-1.2.5"
                onChange={(e) => setVersion(e.target.value)}
                disabled={!edit}
                value={version}
                className="bg-inherit w-full border-none overflow-x-scroll"
              />
              <div
                onClick={() => setEdit(!edit)}
                className="bg-[#17181A] border border-[#2C2E33] rounded-md px-3 py-2 flex justify-center items-center cursor-pointer hover:bg-[#E45799]"
              >
                {edit ? (
                  <RiSaveLine size={16} />
                ) : (
                  <RiEditBoxLine size={16} />
                )}
              </div>
            </div>
            <Typography
              sx={{ fontFamily: "Roboto", fontWeight: "400" }}
              variant="h6"
            >
              Base URLs
            </Typography>
            <div className="my-3 bg-[#0F1011] rounded-md border border-[#2C2E33]">
              {renderSources()}
            </div>
            <div
              onClick={() => setSourceLength(sourceLength + 1)}
              className="flex items-center gap-2 cursor-pointer bg-[#17181A] border border-[#35383E] mt-3 self-start px-4 py-2 rounded-md"
            >
              <img src={PlusIcon} alt="plus-icon" /> Base URL
            </div>
          </div>
          <div className="flex gap-4 items-center justify-end p-4 border-t border-[#2C2E33] w-full">
            <button
              className="bg-[#2D2F37] rounded-md border border-[#35383E] px-4 py-1.5"
              onClick={() => {
                setSourceLength(sources.length);
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button onClick={handleAddBaseURL} className="bg-[#D9509B] rounded-md border border-[#FF86BE] px-4 py-1.5">
              Update
            </button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BaseUrlModal;
