import React, { useState, useEffect } from 'react';
import './App.css';
import CustomButton from './components/CustomButton/CustomButton';
import { SignIn } from './components/SignIn/SignIn';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import { WaitlistedPage } from './components/Waitlisted/WaitlistedPage';
import { ResetPassword } from './components/ResetPassword/ResetPassword';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import TestSuite from './components/TestSuite/TestSuite';
import { GoogleOAuthProvider } from '@react-oauth/google'
import Navbar from './components/Navbar/Navbar'
import AddTC from './components/AddTC/AddTC';
import IndividualTest from './components/IndividualTest/IndividualTest';
import IndividualAPI from './components/IndividualAPI/IndividualAPI';
import BugTracker from './components/BugTracker/BugTracker';
import IndividualBug from './components/IndividualBug/IndividualBug';
import APIOverview from './components/APIOverview/APIOverview';
import { useDispatch, useSelector } from 'react-redux';
import Integrations from './components/Integrations/Integrations';
import SettingsComponent from './components/SettingsComponent/SettingsComponent';
import { loginUserSuccess, validateGoogleToken, googleSSO, checkAuthStatus } from './Actions/userActions';
import ChatBot from './components/ChatBot/ChatBot';
import OrgDetails from './components/orgDetails/orgDetails';
import IntegrateSDK from './components/IntegrateSDK/IntegrateSDK';
import NotFound from './components/NotFound/NotFound';
import { AuthProvider, useAuth } from './AuthContext'; 
import { Margin } from '@mui/icons-material';
import logo from './components/ChatBot/logo2.png';

/*

Main App component that renders the entire application.
The App component uses the react-router-dom library to handle routing in the application.
The App component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The App component uses the useEffect hook to check if the user is already logged in.
The App component renders different components based on the user's authentication status.

*/


// function App() {

//   function WaitlistCheck({ children }) {
//     const navigate = useNavigate();
//     const location = useLocation();
//     // const waitlisted = false; // This should be fetched from your state or API
  
//     useEffect(() => {
//       if (waitlisted && location.pathname !== '/waitlisted') {
//         navigate('/waitlisted', { replace: true });
//       }
//     }, [waitlisted, navigate, location]);
  
//     if (waitlisted && location.pathname !== '/waitlisted') {
//       return <WaitlistedPage />;
//     }
  
//     return children;
//   }
  
//   const dispatch = useDispatch();
//   const { loading, user } = useSelector(state => state.user); // user is the state in the reducer
//   const [verificationCompleted, setVerficationCompleted] = useState(false);

//   const isNew = JSON.parse(localStorage.getItem('isNew'));
//   let waitlisted = JSON.parse(localStorage.getItem('user'))?.waitlisted;
//   waitlisted = waitlisted === true ? true : false;
//   console.log('waitlisted', waitlisted);
//   // Check if the user is already logged in, if so, set the user state
//   const isAuthenticated = localStorage.getItem('isAuthenticated') || sessionStorage.getItem('isAuthenticated');
//   useEffect(() => {
//     const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
//     const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
//     const orgDetail = JSON.parse(localStorage.getItem('user')?.orgDetails || sessionStorage.getItem('orgDetails'))?.orgDetails;
//     const googleToken = localStorage.getItem('token');

//     if(accessToken && !isAuthenticated){
//       localStorage.clear();
//       sessionStorage.clear();
//     } else if (accessToken && user && isAuthenticated) {
//       dispatch(loginUserSuccess({ user, accessToken }));
//     } else if (googleToken) {
//       const currentTime = new Date().getTime();
//       const expirationTime = parseInt(localStorage.getItem('tokenExpirationTime'));

//       if (currentTime <= expirationTime) {
//         dispatch(googleSSO(googleToken));
//       } else {
//         // Token expired, remove it
//         localStorage.removeItem('token');
//         localStorage.removeItem('tokenExpirationTime');
//       }
//     }

//   }, [dispatch]);

//   return (
//     <div className="App">
//       <GoogleOAuthProvider clientId="817077646276-uak1j9jq5ud8cu4qnl892kse1p80pnvg.apps.googleusercontent.com">
//         <Router>
//           <Routes>
//             {/* Routes excluded from waitlist check */}
//             <Route path="/" element={isAuthenticated && !isNew ? <Navigate to="/test-suite" /> : <SignIn />} />
//             <Route path="/login" element={isAuthenticated && !isNew ?  <Navigate to="/test-suite" /> : <SignIn />} />
//             <Route path="/org-details" element={isAuthenticated && isNew && user ? <OrgDetails /> : isAuthenticated && !isNew ? <Navigate to="/test-suite" /> : <Navigate to="/login" />} />
            
//             {/* Routes with waitlist check */}
//             <Route path="/*" element={
//               <WaitlistCheck>
//                 <Routes>
//                   <Route path="/waitlisted" element={<WaitlistedPage />} />
//                   <Route path="/onboarding" element={<WelcomePage />} />
//                   <Route path="/reset-password" element={<ResetPassword />} />
//                   <Route path="/integrate-sdk" element={isAuthenticated && isNew && user && user.orgDetails ? <IntegrateSDK /> : <Navigate to="/login" />} />
//                   <Route path="/chat" element={<ChatBot setVerficationCompleted={setVerficationCompleted} />} />
//                   <Route path="/test-suite" element={isAuthenticated ? <TestSuite /> : <Navigate to="/login" />} />
//                   <Route path="/add-test-case" element={isAuthenticated ? <AddTC /> : <Navigate to="/login" />} />
//                   <Route path='/test/:id' element={isAuthenticated ? <IndividualTest /> : <Navigate to="/login" />} />
//                   <Route path='/api/:id' element={isAuthenticated ? <IndividualAPI /> : <Navigate to="/login" />} />
//                   <Route path='/bug-tracker' element={isAuthenticated ? <BugTracker /> : <Navigate to="/login" />} />
//                   <Route path='/bug/:id' element={isAuthenticated ? <IndividualBug /> : <Navigate to="/login" />} />
//                   <Route path='/api-overview' element={isAuthenticated ? <APIOverview /> : <Navigate to="/login" />} />
//                   <Route path='/integrations' element={isAuthenticated ? <Integrations /> : <Navigate to="/login" />} />
//                   <Route path="/settings" element={isAuthenticated ? <SettingsComponent /> : <Navigate to="/login" />} />
//                   <Route path="*" element={<NotFound />} />
//                 </Routes>
//               </WaitlistCheck>
//             } />
//           </Routes>
//         </Router>
//       </GoogleOAuthProvider>
//     </div>
//   );
// }

// export default App;
// const { user } = useAuth();
function WaitlistCheck({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user?.waitlisted && location.pathname !== '/waitlisted') {
      navigate('/waitlisted', { replace: true });
    }
  }, [user, navigate, location]);

  if (user?.waitlisted && location.pathname !== '/waitlisted') {
    return <WaitlistedPage />;
  }

  return children;
}

function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function App() {
  const dispatch = useDispatch();
  const { checkAuth, user } = useAuth();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1024);  // Set your threshold here
    };

    // Add event listener for screen resize
    window.addEventListener('resize', checkScreenSize);

    // Check screen size initially
    checkScreenSize();

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    checkAuth();
  }, []);

  if (isSmallScreen) {
    return (
      <div className="show-unsupported" >
        <img src= {logo} 
        alt="Logo"
        style={{ width: '100px', marginBottom: '20px' }}
        />
        <div className='unsupported-text'>
      Please Use Desktop or Laptop
      </div>
    </div>
    );
  }

  

  console.log('user', user);

  return (
    <div className="App">
      <GoogleOAuthProvider clientId="817077646276-uak1j9jq5ud8cu4qnl892kse1p80pnvg.apps.googleusercontent.com">
        <Router>
          <Routes>
            <Route path="/" element={user && !user.isNew ? <Navigate to="/test-suite" /> : <SignIn />} />
            <Route path="/login" element={user && !user.isNew ? <Navigate to="/test-suite" /> : <SignIn />} />
            <Route path="/org-details" element={
              <ProtectedRoute>
                {user?.isNew ? <OrgDetails /> : <Navigate to="/test-suite" />}
              </ProtectedRoute>
            } />
            
            <Route path="/*" element={
              <WaitlistCheck>
                <Routes>
                  <Route path="/waitlisted" element={<WaitlistedPage />} />
                  <Route path="/onboarding" element={<WelcomePage />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/integrate-sdk" element={
                    <ProtectedRoute>
                      {user?.isNew && user?.orgDetails ? <IntegrateSDK /> : <Navigate to="/login" />}
                    </ProtectedRoute>
                  } />
                  <Route path="/chat" element={<ChatBot />} />
                  <Route path="/test-suite" element={<ProtectedRoute><TestSuite /></ProtectedRoute>} />
                  <Route path="/add-test-case" element={<ProtectedRoute><AddTC /></ProtectedRoute>} />
                  <Route path='/test/:id' element={<ProtectedRoute><IndividualTest /></ProtectedRoute>} />
                  <Route path='/api/:id' element={<ProtectedRoute><IndividualAPI /></ProtectedRoute>} />
                  <Route path='/bug-tracker' element={<ProtectedRoute><BugTracker /></ProtectedRoute>} />
                  <Route path='/bug/:id' element={<ProtectedRoute><IndividualBug /></ProtectedRoute>} />
                  <Route path='/api-overview' element={<ProtectedRoute><APIOverview /></ProtectedRoute>} />
                  <Route path='/integrations' element={<ProtectedRoute><Integrations /></ProtectedRoute>} />
                  <Route path="/settings" element={<ProtectedRoute><SettingsComponent /></ProtectedRoute>} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </WaitlistCheck>
            } />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
