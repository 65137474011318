import Left from "../../../icons/chevron-left.svg";
import Right from "../../../icons/chevron-right.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllAPIs, triggerE2E } from "../../../Actions/tcActions";
import { all } from "axios";
import { Dialog, Alert, Typography, Snackbar } from "@mui/material";
import CloseIcon from "../../../icons/close.svg";
import { PiUploadSimple, PiPlus } from "react-icons/pi";
import JSONPretty from "react-json-pretty";
import CopyIcon from "../../../icons/copy-icon.svg";
import { useAuth } from "../../../AuthContext";

const SwaggerDoc = () => {
  const dispatch = useDispatch();
  const apis = useSelector((state) => state.apis);
  const [allApis, setAllApis] = useState(null);
  const [baseUrl, setBaseUrl] = useState("");
  const [runModalOpen, setRunModalOpen] = useState(false);
  const [apiModalOpen, setApiModalOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    loading: userLoading,
    // user,
    userError,
  } = useSelector((state) => state.user);

  const { user, apikey, logout } = useAuth();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const data = [];
  const getMethodClasses = (method) => {
    switch (method?.toLowerCase()) {
      case "post":
        return "bg-[#816F33]/40 text-[#FCD34D]";
      case "get":
        return "bg-[#31744A]/40 text-[#4ADE80]";
      case "put":
        return "bg-[#2F5A83]/40 text-[#44A5FF]";
      case "delete":
        return "bg-[#804343]/40 text-[#F87171]";
      case "patch":
        return "bg-[#6B4FBB4D] text-[#96A7FF]"
    }
  };

  const navigate = useNavigate();

  const key = apikey;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;
  const totalPages = Math.ceil(apis?.allApis?.apiNames.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = apis?.allApis?.apiNames?.slice(
    startIdx,
    startIdx + itemsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleRunModalOpen = () => {
    setRunModalOpen(true);
  };

  const handleRunModalClose = () => {
    setRunModalOpen(false);
  };

  const runAllTestsHandler = async (e) => {
    try {
      setLoading(true);
      await dispatch({ type: "onboardingComplete" });
      await dispatch(triggerE2E(baseUrl, key));
      localStorage.setItem('flag', true);
      setLoading(false);
      navigate("/test-suite");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // dispatch(requestNames("e7b769ef-b96d-4135-9a4c-ec9b489a639b"));
    dispatch(getAllAPIs());

  }, []);

  useEffect(() => {
    if (apis) {
      // setAllApis(apis.data);
      setAllApis(apis?.allApis?.apiNames);
    }
  }, []);

  console.log("allAPIs", allApis);

  const isValidBaseUrl = (url) => {
    try {
      const parsedUrl = new URL(url);

      // Ensure that the URL includes a protocol and hostname
      return (
        ["http:", "https:"].includes(parsedUrl.protocol) && !!parsedUrl.hostname
      );
    } catch (e) {
      console.log("error", e);
      return false; // Invalid URL
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleLogout = async () => {
    await logout();
  }

  return (<div className="flex flex-col">
    <div className="text-[#E7E7E7] bg-[#26282F] mt-4 mr-8 self-end border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1 cursor-pointer" onClick={handleLogout}>Log Out</div>
    <div className="px-24 py-10 flex flex-col">
      <div className="flex justify-between">
        <div className="text-[#F37BB3] text-xl font-semibold">
          Swagger Document
        </div>
        <div>
          Number of APIs captured{" "}
          <span className="ml-3 px-4 py-1 bg-[#533543] border border-1 border-[#F37BB3] rounded-md">
            {apis?.allApis?.apiNames.length}
          </span>
        </div>
      </div>
      <div className="grid grid-rows-8 grid-cols-2 gap-x-4 gap-y-2 mt-8">
        {currentApis?.map((api) => (
          <div className="bg-[#0F1011] border border-1 border-[#2E3138] rounded-lg flex px-4 py-2.5 items-center">
            <div
              className={
                "mr-4 rounded-md min-w-[80px] text-center py-2 text-sm font-semibold uppercase " +
                getMethodClasses(api.source.request.method)
              }
            >
              {api.source.request.method}
            </div>
            <div className="flex justify-between gap-2 w-[85%] max-w-[85%] relative items-center">
              <div className="relative w-[75%] max-w-[75%] overflow-x-scroll no-scrollbar">
                <div>{api.name}</div>
                <div className="text-[10px] text-wrap mt-1 text-[#B0B0B0]">
                  {String(api.source.request.path)}
                </div>
              </div>
              <div
                onClick={() => {
                  setApiModalOpen(true);
                  setSelectedApi(api);
                }}
                className="bg-[#16171A] min-[100px] text-center border border-1 border-[#43454A] px-3 py-1 rounded-md text-sm cursor-pointer"
              >
                view more
              </div>
            </div>
          </div>
        ))}
        <div className="flex items-center self-end">
          <div className="flex text-[#EFEFEF] mr-2">
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-[#B0B0B0] text-sm mr-3 px-3 py-1"
              onClick={handlePrevPage}
            >
              <img src={Left} className="mr-2" /> Prev
            </div>
            <div
              className={
                "grid gap-2 mr-3 " +
                (startIdx + itemsPerPage >= apis?.allApis?.apiNames?.length
                  ? "grid-cols-2"
                  : "grid-cols-3")
              }
            >
              {startIdx + itemsPerPage >= apis?.allApis?.apiNames?.length ? (
                <>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage - 1}
                  </div>
                  <div className="border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                    {currentPage}
                  </div>
                </>
              ) : currentPage > 1 ? (
                <>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage - 1}
                  </div>
                  <div className="border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                    {currentPage}
                  </div>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 1}
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-[#F257A0] p-1 w-[34px] text-center rounded-md">
                    {currentPage}
                  </div>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 1}
                  </div>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 2}
                  </div>
                </>
              )}
            </div>
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-sm px-3 py-1 mr-4"
              onClick={handleNextPage}
            >
              Next <img className="ml-2" src={Right} />
            </div>
          </div>
          <div className="text-[#EFEFEF] text-sm font-light">
            {" "}
            {startIdx + 1 === apis?.allApis?.apiNames?.length
              ? startIdx + 1
              : startIdx + itemsPerPage > apis?.allApis?.apiNames?.length
                ? apis?.allApis?.apiNames?.length
                : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
            of {apis?.allApis?.apiNames?.length} Results
          </div>
        </div>
      </div>
      <div
        onClick={handleRunModalOpen}
        className="cursor-pointer w-1/3 py-2.5 mt-8 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center self-center"
      >
        Proceed to API analysis
      </div>
      <Dialog
        open={runModalOpen}
        onClose={handleRunModalClose}
        PaperProps={{
          style: {
            height: "40%",
            width: "400px",
            backgroundColor: "#21222D",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #3D3D3D",
            overflow: "hidden",
          },
        }}
      >
        <div className="run-modal">
          <div className="close-run" onClick={handleRunModalClose}>
            <img src={CloseIcon} />
          </div>
          <div className="add-ver">
            <Typography>Add Version</Typography>
          </div>
          <div className="ver-input">
            <input
              placeholder="Enter the Base URL"
              value={baseUrl}
              onChange={(e) => {
                setBaseUrl(e.target.value);
              }}
              style={{
                border: !isValidBaseUrl(baseUrl) ? '2px solid red' : '1px solid #ccc',
              }}
            />
            {!isValidBaseUrl(baseUrl) && (
              <p style={{ color: 'red', marginTop: '5px', fontSize: '12px', marginBottom: '5px' }}>
                eg. https://api.example.com or http://api.example.com
              </p>
            )}
            {/* <input
                            placeholder='Enter the Source Name'
                            value={source}
                            onChange={(e) => { setSource(e.target.value) }}
                            style={{
                                marginTop: "30px"
                            }}
                        /> */}
          </div>
          <div className={`run-btn`}>
            <button
              className={`run-tests${loading ? "-disabled" : ""}`}
              onClick={() => {
                runAllTestsHandler();
              }}
              disabled={(!isValidBaseUrl(baseUrl))}
            >
              Confirm And Run Tests
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        maxWidth="85vw"
        open={apiModalOpen}
        onClose={() => setApiModalOpen(false)}
        PaperProps={{
          style: {
            minHeight: "fit",
            width: "75vw",
            backgroundColor: "#17171A",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #2C2E33",
          },
        }}
      >
        <div className="px-12 py-8 w-full flex flex-col gap-2">
          <div
            onClick={() => setApiModalOpen(false)}
            className="rotate-45 cursor-pointer text-[#B0B0B0] self-end"
          >
            <PiPlus size={20} />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div
                className={
                  "mr-4 rounded-md min-w-[80px] text-center py-1.5 text-sm font-semibold " +
                  getMethodClasses(selectedApi?.source?.request?.method)
                }
              >
                {selectedApi?.source.request.method}
              </div>
              <div className="font-semibold">{selectedApi?.name}</div>
            </div>
            <div className="rounded-md border border-1 bg-[#0F1011] text-nowrap border-[#2C2E33] px-3 py-1.5 min-w-[10vw] max-w-[30vw] overflow-x-scroll no-scrollbar text-sm">
              {selectedApi?.source.request.path}
            </div>
          </div>
          {selectedApi?.source?.request?.headers ? (
            <div className="mt-4">
              <div className="font-semibold">Headers</div>
              <div className="text-sm font-light border border-1 border-[#2C2E33] rounded-md max-h-[40vh] mt-2 overflow-y-scroll no-scrollbar">
                {Object.entries(selectedApi?.source?.request?.headers).map(
                  ([key, value]) => (
                    <div className="grid grid-cols-6">
                      <div className="col-span-2 px-4 py-2 bg-[#0F1011] border-b border-r border-[#2C2E33]">
                        {key}
                      </div>
                      <div className="col-span-4 overflow-x-scroll no-scrollbar whitespace-normal px-4 py-2 bg-[#0F1011] border-b border-[#2C2E33]">
                        {String(value)}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          <div className="mt-4 grid grid-cols-2 gap-4">
            <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-md">
              <div className="bg-[#17181A] border-b border-[#2C2E33] p-3 flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <div className="border border-[#533543] rounded-md px-3 py-0.5">1</div>
                  <select name="body1" id="body1" className="border border-[#533543] bg-inherit rounded-md min-w-[200px] px-3 py-1">
                    <option value="body1">Request Body 1</option>
                  </select>
                </div>
                <img
                  onClick={() =>
                    copyToClipboard(
                      JSON.stringify(selectedApi?.source?.request?.body ?? {})
                    )
                  }
                  className="w-[16px] h-[16px] cursor-pointer"
                  src={CopyIcon}
                  alt="copy icon"
                />
              </div>
              <div className="h-[30vh] max-h-[30vh] px-3 py-3 bg-[#0F1011] text-[#B0B0B0]">
                <JSONPretty
                  style={{ overflowY: "scroll", height: "100%" }}
                  id="json-pretty"
                  themeClassName="text-sm"
                  keyStyle="color: #E45799;"
                  data={JSON.stringify(
                    selectedApi?.source?.request?.body ?? {}
                  )}
                ></JSONPretty>
              </div>
            </div>
            <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-md">
              <div className="bg-[#17181A] border-b border-[#2C2E33] px-3 py-4 flex justify-between items-center">
                Response{" "}
                <img
                  onClick={() =>
                    copyToClipboard(
                      JSON.stringify(
                        selectedApi?.source?.response?.content ?? {}
                      )
                    )
                  }
                  className="w-[16px] h-[16px] cursor-pointer"
                  src={CopyIcon}
                  alt="copy icon"
                />
              </div>
              <div className="h-[30vh] max-h-[30vh] p-3 bg-[#0F1011] text-[#B0B0B0] relative">
                <JSONPretty
                  style={{ overflowY: "scroll", height: "100%", paddingTop: "1rem" }}
                  id="json-pretty"
                  themeClassName="text-sm"
                  keyStyle="color: #E45799;"
                  data={JSON.stringify(
                    selectedApi?.source?.response?.content ?? {}
                  )}
                ></JSONPretty>
                <div className={"absolute top-3 right-3 rounded-md max-h-fit py-0.5 px-3 text-white " + (selectedApi?.source?.response?.status_code < (300) ? "bg-[#31744A]" : "bg-[#633238]")}>{selectedApi?.source?.response?.status_code}</div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={copied}
        autoHideDuration={1000}
        onClose={() => setCopied(false)}
      >
        <Alert severity="success">Copied to clipboard</Alert>
      </Snackbar>
    </div>
  </div>
  );
};

export default SwaggerDoc;
