import SDKCard from './SDKCard/SDKCard';
import { Typography, Alert, Snackbar } from '@mui/material';
import { Link } from "react-router-dom";
import { PiLink, PiArrowLeftBold, PiCopy } from "react-icons/pi";
import nodeIcon from '../../../icons/node-icon.svg'
import pythonIcon from '../../../icons/python-icon.svg'
import javaIcon from '../../../icons/java-icon.svg'
import golangIcon from '../../../icons/golang-icon.svg'
import CopyIcon from '../../../icons/copy-icon.svg';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getAPICount } from '../../../Actions/tcActions';
import {CircularProgress} from '@mui/material';
import { useAuth } from '../../../AuthContext';

const SDK = ({setPageStatus}) =>{
    const [noKey, setNoKey] = useState(false)

    const dispatch = useDispatch();

    const {
        loading: userLoading,
        // user,
        isNew,
        isAuthenticated,
    } = useSelector((state) => state.user);
    const { user, apikey, logout } = useAuth();
    const {
        loading: apiLoading,
        error,
        userApiCount
    } = useSelector((state) => state.apis);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSnackbarOpen(false);
      };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apikey);
        setSnackbarOpen(true);
        setAlertMessage("Copied to clipboard");
        setAlertSeverity("success");
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 1000);
    };

    const handleProceed = () => {
        setPageStatus({keyIntegrated: true, apiCollected: false, verificationCompleted: false});
    }

    useEffect(() => {
        dispatch(getAPICount())
    }, [])

    useEffect(() => {
        if (error) {
            setAlertSeverity("error");
            setAlertMessage(error);
            setSnackbarOpen(true);
            dispatch({type: 'clearError'})
        }
    }, [error])

    const renderComponent = () => {
        setTimeout(() => {
            setNoKey(true)
        }, 4000);
        return noKey ? <div onClick={() => {dispatch(getAPICount()); setNoKey(false); setTimeout(() => {
            setNoKey(true)
        }, 4000);}} className="cursor-pointer w-1/4 py-3 mt-16 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center">Refresh</div> : <div className="mt-16 flex items-center"><span className='text-[#D9509B] flex items-center mr-3'><CircularProgress size={20} color="inherit" /></span>Waiting for the API key to be added into your project</div>
    }

    const handleLogout = async () => {
        await logout();
    }


    return  <div className="px-16 py-10">
    <div className='flex justify-end'>
        <div className="text-[#F37BB3] text-xl font-semibold">Integrate Our SDK into your application</div>
        <div className='flex gap-2 ml-[18vw]'>
            <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1 cursor-pointer"onClick={handleLogout}>Log Out</div>
            <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1">Need Help?</div>
        </div>
    </div>
    <div className="w-full flex flex-col items-center justify-between">
        <div className="mt-24">Get started by using this API key with all our SDKs</div>
        <div className="bg-[#0F1011] rounded-md border border-1 border-[#585858] px-4 py-2.5 mt-3 w-1/2 flex justify-between items-center text-[#B0B0B0]">
            {apikey}
            <PiCopy size={20} className='cursor-pointer hover:text-[#D9509B]' onClick={copyToClipboard} />
        </div>
        <div className="mt-20">Select required SDK</div>
        <div className="grid grid-cols-2 gap-3 mt-4">
            <SDKCard lang="Node" image={nodeIcon} githubUrl="https://github.com/devzery/devzery_middleware_express"/>
            <SDKCard lang="python" image={pythonIcon} githubUrl="https://github.com/devzery/devzery_middleware"/>
            <SDKCard lang="java" image={javaIcon} githubUrl="https://github.com/devzery/devzery-middleware-springboot"/>
            {/* <SDKCard lang="golang" image={golangIcon} docsUrl="https://github.com/devzery/devzery_middleware" githubUrl="https://github.com/devzery/devzery_middleware"/> */}
        </div>
        {userApiCount?.data?.count > 0 ? 
        <div onClick={handleProceed} className="cursor-pointer w-1/3 py-3 mt-16 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center">Proceed to API analysis</div> 
        : renderComponent()}
    </div>
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
</div>
}

export default SDK